import React from 'react';
import './App.css';
import Home from './Home';
import Menu from '../components/Menu';
import {SocialIcon} from 'react-social-icons';
import {Container, Row, Col} from 'react-bootstrap';

const Index = () => {
    return (
        <>
            <Menu/>
            <div id="main">
                <Home path="/"/>
            </div>
            <Container className="App-footer" fluid>
                <Row>
                    <Col>
                        <SocialIcon title="Sensei Ravey"
                                    url="https://www.facebook.com/pages/Sensei-Graham-Ravey/117797741595321?fref=ts&ref=br_tf"/>
                    </Col>
                </Row>
            </Container>
        </>);
};

export default Index;
